import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'Top',
    component: () => import(/* webpackChunkName: "about" */ '../views/Top.vue')
  },
  {
    path: "/form",
    name: "Form",
    component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue')
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: "/thanks",
    name: "Thanks",
    component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue')
  },
  {
    path: "/download_complete",
    name: "DownloadComplete",
    component: () => import(/* webpackChunkName: "thanks" */ '../views/DownloadComplete.vue')
  },
  {
    path: '/interview/modis',
    name: 'InterviewModis',
    component: () => import(/* webpackChunkName: "about" */ '../views/interview/modis.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

